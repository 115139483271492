<template>
  <transition name="showHeader">
    <div class="header-animat">
      <a-layout-header :class="['ant-header-side-opened']" :style="{padding:'0'}">
        <div :class="['top-nav-header-index','light']">
          <div class="header-index-wide">
            <div class="header-index-left">
              <!--logo-->
              <logo class="top-nav-header" />
              <!--menu-->
              <a-menu mode="horizontal">
                <a-menu-item key="100">
                  <router-link :to="{name:'index'}">首页</router-link>
                </a-menu-item>
                <!-- <a-menu-item key="101">
                <router-link :to="{name:'about'}">关于</router-link>
                </a-menu-item>-->
              </a-menu>
            </div>
            <!--user-->
            <head-user-menu class="header-index-right"></head-user-menu>
          </div>
        </div>
      </a-layout-header>
    </div>
  </transition>
</template>

<script>
  import Logo from './Logo'
  import HeadUserMenu from './HeadUserMenu'
  export default {
    name: 'GlobalHeader',
    components: {
      Logo,
      HeadUserMenu
    },
    props: {},
    data() {
      return {
        logoUrl: require('@/assets/logo.png')
      }
    },
    computed: {

    },
    mounted() {},
    methods: {

    }
  }
</script>

<style lang="less">
  @import "../index.less";

  .header-animat {
    position: relative;
    z-index: @ant-global-header-zindex;
  }

  .showHeader-enter-active {
    transition: all 0.25s ease;
  }

  .showHeader-leave-active {
    transition: all 0.5s ease;
  }

  .showHeader-enter,
  .showHeader-leave-to {
    opacity: 0;
  }
</style>
