import {
  gbUserTypes
} from './types'

const setId = (state, id) => {
  state.id = id || 0
}

const setUid = (state, uid) => {
  state.uid = uid || ''
}

const setToken = (state, token) => {
  state.token = token || ''
}

const setRealname = (state, realname) => {
  state.realname = realname || ''
}

const setNickName = (state, nickname) => {
  state.nickname = nickname || ''
}

const setAvatar = (state, avatar) => {
  state.avatar = avatar || ''
}
const mutations = {
  [gbUserTypes.SET_ID]: setId,
  [gbUserTypes.SET_UID]: setUid,
  [gbUserTypes.SET_TOKEN]: setToken,
  [gbUserTypes.SET_REALNAME]: setRealname,
  [gbUserTypes.SET_NICKNAME]: setNickName,
  [gbUserTypes.SET_AVATAR]: setAvatar
}
export default mutations
