import Vue from 'vue'
import VueRouter from 'vue-router'
import { baseRouterMap } from '@/config/router.config'

import { signIn } from '../api/OauthConfig'
import { ACCESS_TOKEN } from '../store/mutation-types'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: baseRouterMap
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta && to.meta.needLogin) {
    if (Vue.ls.get(ACCESS_TOKEN)) {
      //can add more routes by roles
      next()
    } else {
      signIn(to.fullPath)
    }
  } else {
    next()
  }
})

export default router
