import Vue from 'vue'

import {
  ACCESS_TOKEN
} from '../../mutation-types'

import {
  signIn,
  signOut,
  getUser
} from '@/api/OauthConfig'

import {
  gbUserTypes
} from './types'

/**
 * 获取登入人信息
 */
const getUserInfo = ({
  commit,
  dispatch,
  state
}) => {
  return new Promise((resolve, reject) => {
    var res = {
      id: 1,
      uid: 'U000000',
      username: '张三',
      nickname: '张三莽夫',
      avatar: ''
    };
    commit(gbUserTypes.SET_ID, res.id)
    commit(gbUserTypes.SET_UID, res.uid)
    commit(gbUserTypes.SET_TOKEN, '')
    commit(gbUserTypes.SET_REALNAME, res.username)
    commit(gbUserTypes.SET_NICKNAME, res.nickname)
    commit(gbUserTypes.SET_AVATAR, res.avatar)
    resolve(res)

    // getUser()
    //   .then(res => {
    //     res = { name: '张三' }
    //     commit('SET_NICKNAME', res.name)
    //     resolve(res)
    //   })
    //   .catch(err => reject(err))
  })
}

/**
 * 登出功能
 */
const logout = ({
  commit,
  dispatch,
  state
}) => {
  return new Promise(resolve => {
    commit(gbUserTypes.SET_TOKEN, '')
    Vue.ls.remove(ACCESS_TOKEN)
    signOut()
      .then(() => {
        window && window.localStorage.clear()
        resolve()
      })
      .catch(() => {
        window && window.localStorage.clear()
        resolve()
      })
  })
}

/**
 * 登入功能
 */
const login = ({
  commit,
  dispatch
}, payload) => {
  signIn(payload.fullPath || '')
}

const actions = {
  getUserInfo,
  logout,
  login
}

export default actions
