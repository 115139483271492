import {
  getHotArticlesApi,
  getDefaultArticlesApi,
  initArticleShowApi,
  starArticleApi,
  getArticleComments,
  addArticleComment
} from '@/api/article'

import {
  articleTypes
} from './types'

/**
 * 初始化显示文章信息
 */
const initArticleShow = ({
    commit,
    dispatch
  },
  payload) => {
  initArticleShowApi({
    pid: payload.pid || ''
  }).then(res => {
    if (res && res.code == 200) {
      commit(articleTypes.INIT_ARTICLESHOW, res.data)
    } else {
      commit(articleTypes.INIT_ARTICLESHOW)
    }
  })
}

/**
 * 初始化文章详情下的留言列表
 */
const initArticleComments = ({
    commit,
    dispatch
  },
  payload) => {
  getArticleComments(payload).then(res => {
    if (res && res.code == 200) {
      commit(articleTypes.INIT_ARTICLECOMMENTS, res)
    } else {
      commit(articleTypes.INIT_ARTICLECOMMENTS)
    }
  })
}

/**
 * 收藏文章
 */
const starArticle = ({
    commit,
    dispatch
  },
  payload) => {
  starArticleApi({
    pid: payload.pid || ''
  }).then(res => {
    if (res && res.code == 200) {
      commit(articleTypes.SET_STAR)
    }
  })
}

const actions = {
  initArticleShow,
  initArticleComments,
  starArticle
}
export default actions
