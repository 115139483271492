import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '../store/mutation-types'

//Create instance of axios
const service = axios.create({
  baseURL:process.env.VUE_APP_API_BASE_URL,
  timeout: 30000
})

//default error infomation
const err = error => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      // notification.error({
      //   message: '访问错误',
      //   description: data.message || '抱歉，访问被禁止，请检查是否有权限！'
      // })
      console.error("抱歉，访问被禁止，请检查是否有权限！")
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // notification.error({
      //   message: '访问未授权',
      //   description: '抱歉，访问授权验证失败，请重新登录！'
      // })
      console.error("抱歉，访问授权验证失败，请重新登录！")
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}
// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use(response => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
