<template>
  <div class="footer">
    <div class="copyright">
      Copyright
      <a-icon type="copyright" />2021
      <span>干吧网 V1.0</span>
    </div>
    <div class="copyright">
      <a href="http://beian.miit.gov.cn/" style="color:#878889;" target="_blank">苏ICP备20035881号</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.footer {
  padding: 0 16px;
  margin: 24px 0 24px;
  text-align: center;

  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>