import {
  axios
} from '@/utils/request'

/**
 * 首页获取热门文章
 * @param {*} params 
 * @returns 热门文章列表
 */
export function getHotArticlesApi(params) {
  return axios({
    url: '/home/gethotarticles',
    method: 'get',
    params: params
  })
}

/**
 * 首页获取默认文章
 * @param {*} params 分页信息
 * @returns 文章列表
 */
export function getDefaultArticlesApi(params) {
  return axios({
    url: '/home/getdefaultarticles',
    method: 'post',
    data: params
  })
}

/**
 * 获取前台显示文章详情内容
 * @param {Object} params 文章PID
 * @return {Object} 文章详情信息
 */
export function initArticleShowApi(params) {
  return axios({
    url: '/home/getarticleinfo',
    method: 'get',
    params: params
  })
}

/**
 * 收藏文章
 * @param {Object} params 文章信息
 */
export function starArticleApi(params) {
  return axios({
    url: '/article/star',
    method: 'get',
    params: params
  })
}

/**
 * 获取文章详情下的留言列表
 * @param {Object} params 分页信息
 */
export function getArticleComments(params) {
  return axios({
    url: '/home/getarticlecommnets',
    method: 'post',
    data: params
  })
}

/**
 * 添加文章留言
 * @param {Object} params 留言信息实体
 */
export function addArticleComment(params) {
  return axios({
    url: '/article/addarticlecomment',
    method: 'post',
    data: params
  })
}
