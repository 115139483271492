<template>
  <a-layout :class="['layout','desktop']">
    <a-layout :class="['topmenu','content-width-Fixed']" :style="{ paddingLeft: '0px', minHeight: '100vh' }">
      <global-header></global-header>
      <a-layout-content :style="{ height: '100%', margin: '24px 24px 0', paddingTop: '0' }">
        <transition name="page-transition">
          <route-view />
        </transition>
      </a-layout-content>
      <a-layout-footer>
        <global-footer></global-footer>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import RouteView from './RouteView'
import GlobalHeader from '@/components/GlobalHeader/GlobalHeader.vue'
import GlobalFooter from '@/components/GlobalFooter/GlobalFooter.vue'
export default {
  name: 'BasicLayout',
  components: {
    RouteView,
    GlobalHeader,
    GlobalFooter
  },
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() { },
  methods: {}
}
</script>

<style lang="less">
@import url("../components/global.less");

/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */

.page-transition-enter {
  opacity: 0;
}

.page-transition-leave-active {
  opacity: 0;
}

.page-transition-enter .page-transition-container,
.page-transition-leave-active .page-transition-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>