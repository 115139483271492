import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const articleInfos = {
  namespaced: true,
  state: {
    articleShow: {
      id: 0, //主键ID
      pid: '', //文章PID
      title: '', //标题
      ownResource: '', //拥有的资源
      content: '', //内容
      ownerUid: '', //创建人UID
      ownerName: '', //创建人名称
      ownerNickName: '', //创建人昵称
      ownerAvatar: '', //创建人头像
      createDateTime: '', //创建时间
      star: false, //收藏
    },
    articleComments: {
      pageIndex: 1, //页号
      pageSize: 20, //页大小
      total: 0, //数据总量
      list: [{ //留言列表
        id: 0,
        pid: '',
        senderUid: '',
        senderAvatar: '',
        senderNickName: '',
        reciverUid: '',
        reciverAvatar: '',
        reciverNickName: '',
        content: '',
        createDatetime: '',
        forbidden: false,
      }]
    }
  },
  getters,
  actions,
  mutations
}

export default articleInfos
