import {
  Promise
} from 'core-js'

import {
  UserManager,
  WebStorageStateStore
} from 'oidc-client'

import Vue from 'vue'
import {
  ACCESS_TOKEN
} from '../store/mutation-types'

const userManager = new UserManager({
  userStore: new WebStorageStateStore({
    store: window.localStorage
  }),
  authority: process.env.VUE_APP_IDENTITY_URL,
  client_id: 'partnerfront',
  response_type: 'id_token token',
  scope: 'openid profile partnerfront',
  redirect_uri: process.env.VUE_APP_HOST_URL + '/oauth/callback',
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.VUE_APP_HOST_URL + '/oauth/refresh',
  metadata: {
    issuer: process.env.VUE_APP_IDENTITY_URL,
    authorization_endpoint: process.env.VUE_APP_IDENTITY_URL + '/connect/authorize',
    userinfo_endpoint: process.env.VUE_APP_IDENTITY_URL + '/connect/userinfo',
    jwks_uri: process.env.VUE_APP_IDENTITY_URL + '/.well-known/openid-configuration/jwks',
    end_session_endpoint: process.env.VUE_APP_IDENTITY_URL + '/connect/endsession',
    check_session_iframe: process.env.VUE_APP_IDENTITY_URL + '/connect/checksession'
  }
})

/**
 * 登入
 * @param {string} returnPath 
 */
export function signIn(returnPath) {
  if (window) {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }
  Vue.ls.remove(ACCESS_TOKEN)
  if (returnPath) {
    return userManager.signinRedirect({
      state: returnPath
    })
  }
  return userManager.signinRedirect()
}

/**
 * 登出
 */
export function signOut() {
  return userManager
    .signoutRedirect()
    .then(resp => {
      console.log("signOut", resp)
    })
    .catch(err => {
      console.error(err)
    })
}

/**
 * 登入直接返回
 */
export function signInRedirectCallback() {
  return userManager.signinRedirectCallback()
}

/**
 * 隐式登入回调
 */
export function signInSilentCallback() {
  return userManager.signinSilentCallback()
}

/**
 * 获取用户信息
 */
export function getUser() {
  return new Promise((resolve, reject) => {
    userManager.getUser()
      .then(user => {
        if (!user || user.expired) {
          console.log('user expired', user)
          const path = window.location.pathname
          signIn(path || '/')
          resolve(null)
        } else {
          Vue.ls.set(ACCESS_TOKEN, user.access_token)
          console.log('refesh token.....')
          resolve(user)
        }
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })
}
