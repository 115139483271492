import Vue from 'vue'
import VueStorage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueAxios } from './utils/request'
import antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.less'

Vue.config.productionTip = false
//use vue-ls
Vue.use(VueStorage, { namespace: 'ptr__', name: 'ls', storage: 'local' })
//use axios;mount axios to Vue.$http and this.$http
Vue.use(VueAxios)
//use
Vue.use(antd)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
