import {
  articleTypes
} from './types'

/**
 * 初始化文章显示内容
 */
const initArticleShow = (state, payload) => {
  if (payload) {
    state.articleShow.id = payload.id || 0
    state.articleShow.pid = payload.pid || ''
    state.articleShow.title = payload.title || ''
    state.articleShow.ownResource = payload.ownResource || ''
    state.articleShow.content = payload.content || ''
    state.articleShow.ownerUid = payload.ownerUid || ''
    state.articleShow.ownerName = payload.ownerName || ''
    state.articleShow.ownerNickName = payload.ownerNickName || ''
    state.articleShow.ownerAvatar = payload.ownerAvatar || ''
    state.articleShow.createDateTime = payload.createDateTime || ''
    state.articleShow.star = payload.star || false
  } else {
    state.articleShow.id = 0
    state.articleShow.pid = ''
    state.articleShow.title = ''
    state.articleShow.ownResource = ''
    state.articleShow.content = ''
    state.articleShow.ownerUid = ''
    state.articleShow.ownerName = ''
    state.articleShow.ownerNickName = ''
    state.articleShow.ownerAvatar = ''
    state.articleShow.createDateTime = ''
    state.articleShow.star = false
  }
}

/**
 * 初始化文章下面的留言信息
 */
const initArticleComments = (state, payload) => {
  if (payload) {
    state.articleComments.pageIndex = payload.pageIndex || 1;
    state.articleComments.pageSize = payload.pageSize || 20;
    state.articleComments.total = payload.total || 0;
    state.articleComments.list = payload.data || []
  } else {
    state.articleComments.pageIndex = 1;
    state.articleComments.pageSize = 20;
    state.articleComments.total = 0;
    state.articleComments.list = []
  }
}

/**
 * 收藏文章
 */
const setStart = (state, payload) => {
  state.articleShow.star = !state.articleShow.star
}

const mutations = {
  [articleTypes.INIT_ARTICLESHOW]: initArticleShow,
  [articleTypes.INIT_ARTICLECOMMENTS]: initArticleComments,
  [articleTypes.SET_STAR]: setStart,
}

export default mutations
