import Vue from 'vue'
import Vuex from 'vuex'
import gbUser from './modules/globaluser'
import articleInfos from './modules/article'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    gbUser,
    articleInfos
  }
})
