import {
	BasicLayout,
	RouteView
} from '@/layouts'
export const baseRouterMap = [{
		path: '/',
		name: 'index',
		component: BasicLayout,
		meta: {
			title: '首页'
		},
		redirect: '/home/index',
		children: [
			//home
			{
				path: '/home',
				name: 'home',
				hidden: true,
				redirect: '/home/index',
				component: RouteView,
				meta: {
					title: '首页',
					keepAlive: false,
				},
				children: [{
					path: '/home/index',
					name: 'homeindex',
					meta: {
						title: '首页',
						keepAlive: false
					},
					component: () => import( /* webpackChunkName: "home" */ '@/views/home/index')
				}]
			},
			//article
			{
				path: '/p/:pid',
				name: 'articleshow',
				meta: {
					title: '文章内容',
					keepAlive: false
				},
				component: () => import( /* webpackChunkName: "articleshow" */ '@/views/article/ArticleShow')
			}
		]
	},
	{
		path: '/about',
		name: 'about',
		meta: {
			title: '关于'
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/about/index.vue')
	},
	{
		path: '/oauth/callback',
		name: 'oauthcallback',
		meta: {
			title: '登入回调'
		},
		component: () => import( /* webpackChunkName: "oauthcallback" */ '../views/oauth/Callback.vue')
	},
	{
		path: '/oauth/refresh',
		name: 'oauthrefresh',
		meta: {
			title: '授权刷新'
		},
		component: () => import( /* webpackChunkName: "oauthrefresh" */ '../views/oauth/Refresh.vue')
	},
	{
		path: '/403',
		name: '403',
		meta: {
			title: '无权限'
		},
		component: () => import( /* webpackChunkName: "error" */ '../views/error/403.vue')
	},
	{
		path: '/404',
		name: '404',
		meta: {
			title: '找不到页面'
		},
		component: () => import( /* webpackChunkName: "error" */ '../views/error/404.vue')
	},
	{
		path: '/500',
		name: '500',
		meta: {
			title: '服务器错误'
		},
		component: () => import( /* webpackChunkName: "error" */ '../views/error/500.vue')
	},
	{
		path: '*',
		redirect: '/404',
		hidden: true
	},
	{
		path: '/usercenter/basicinfo',
		name: 'userbasicinfo',
		meta: {
			title: '账号管理-基本资料',
			needLogin: true
		},
		component: () => import( /* webpackChunkName: "error" */ '../views/usercenter/index.vue')
	}
]
