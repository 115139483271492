/**
 * 前台呈现-文章详情
 */
const articleShow = state => {
  return state.articleShow
}

/**
 * 前台呈现-文章详情下的留言列表
 */
const articleComments = state => {
  return state.articleComments
}

const getters = {
  articleShow,
  articleComments
}

export default getters
