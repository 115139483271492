import getters from './getters'
import actions from './actions'
import mutations from './mutations'
const gbUser = {
  namespaced: true,
  state: {
    id: 0,//主键ID
    uid: '',//用户ID
    token: '',//令牌
    realname: '',//真实用户名
    nickname: '',//名称
    avatar: ''//头像地址
  },
  getters,
  actions,
  mutations
}

export default gbUser