<template>
  <div class="logo">
    <router-link :to="{name:'home'}">
      <img src="@/assets/logo.png" alt />
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Logo',
  components: {
  },
}
</script>

<style scoped>
</style>