<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a-dropdown v-if="gbUserInfo.id>0">
        <span class="action ant-dropdown-link user-dropdown-menu">
          <a-avatar class="avatar" size="small" :src="gbUserInfo.avatar" />
          <span style="vertical-align: middle;">{{ gbUserInfo.nickname }}</span>
        </span>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
          <a-menu-item key="0">
            <router-link :to="{ name: 'index' }">
              <a-icon type="user" />
              <span>个人中心</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="1">
            <router-link :to="{ name: 'about' }">
              <a-icon type="safety" />
              <span>更新密码</span>
            </router-link>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="2">
            <a href="javascript:;">
              <a-icon type="logout" />
              <span>退出登录</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <div v-else>
        <a href="javascript:;" style="color: rgba(0, 0, 0, 0.65);" @click="login(fullPath)">
          <a-icon type="user" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapGetters
  } from 'vuex'

  export default {
    name: 'HeadUserMenu',
    components: {},
    computed: {
      ...mapGetters('gbUser', ['gbUserInfo'])
    },
    data() {
      return {
        fullPath: this.$route.fullPath || ''
      }
    },
    methods: {
      ...mapActions('gbUser', ['login'])
    }
  }
</script>

<style scoped>
  .barrage-tip {
    font-size: 18px;
    vertical-align: middle;
    padding-left: 6px;
  }
</style>
